declare global {
  interface Window {
    switchLanguage: (lang: string) => void;
    changeLanguage: (lang: string) => void;
  }
}

interface Translation {
    [key: string]: string;
  }
  
interface Translations {
    [languageCode: string]: Translation;
    en: Translation;
    it: Translation;
  }
  
export const translations: Translations = {
  en: {
    title: 'Era Collection',
    loading: 'Loading... Please wait',
    parisi: 'https://www.parisigioielli.com/en/66-collezione-era',
    section_1_title: 'Wear\\nHistory',
    buy: 'Buy now',
    section_1_text:
      'History gives us one of the most exciting testimonies of the goldsmith tradition. Today you can wear this emotion. Reproductions of <strong>Graecia&apos;s finest goldsmithing, in limited edition</strong>',
    explore: 'EXPLORE',
    scroll: 'Scroll to the end to explore',
    scroll2: 'Scroll more to make it your',
    master: 'Masterpiece',
    section_2_top_title: 'a vestige of',
    section_2_title: 'HELLENIC\\nHERITAGE',
    section_2_text:
      'A masterpiece of antiquity for the modern woman&apos;s sophistication',
    lux: 'Luxury',
    section_3_top_title: 'Icon of',
    section_3_title: 'AN ERA',
    section_3_text:
      'A mesmerizing earring that exudes luxury and elegance. Choose between a classic, plain version or adorned with a dazzling display of diamonds, sapphires, rubies, or emeralds',
    custom_button: 'Customize it',
    remove_gems: 'Remove Gems',
    add_gems: 'With gems',
    customize: 'Customize',
    customize_class: 'customize--title',
    copy: 'Created by <a target="_blank" href="https://www.ramatolab.com/en">RaMaTo Lab</a>'
  },
  it: {
    title: 'Collezione Era',
    loading: 'Caricando... Qualche istante',
    parisi: 'https://www.parisigioielli.com/it/66-collezione-era',
    section_1_title: 'Indossa\\nla Storia',
    buy: 'Acquista',
    section_1_text:
      'La Storia ci regala una delle testimoninaze più importanti della tradizione orafa. Oggi puoi indossare questa emozione. Riproduzione della migliore <strong>arte orafa della Magna Grecia, in edizione limitata</strong>',
    explore: 'SCOPRI',
    scroll: 'Scorri fino in fondo per esplorare',
    scroll2: 'Scorri ancora per renderlo unico',
    master: 'Capolavoro',
    section_2_top_title: 'una vestigio di',
    section_2_title: 'EREDITÀ\\nELLENICA',
    section_2_text:
      'Un capolavoro dell&apos;antichità per la raffinatezza della donna moderna',
    lux: 'Lusso',
    section_3_top_title: 'Icona di',
    section_3_title: 'UN&apos;ERA',
    section_3_text:
      'Un gioiello incantevole che esprime lusso ed eleganza, proposto in versione classica o impreziosito con una raffinata scelta di diamanti, zaffiri, rubini o smeraldi',
    custom_button: 'Personalizza',
    remove_gems: 'Rimuovi Gemme',
    add_gems: 'Con Gemme',
    customize: 'Personalizza',
    customize_class: 'customize--title--it',
    exit: 'Esci',
    copy: 'Creato da <a target="_blank" href="https://www.ramatolab.com/it">RaMaTo Lab</a>'
  },
}

// Get the language code from the URL

export function getLanguageFromUrl(): string {
  const urlParams = new URLSearchParams(window.location.search);
  const lang = urlParams.get('lang') || 'it';
  return lang;
}

// Change the language and update the content
export function changeLanguage(lang: string): void {
  const elements = document.querySelectorAll('[data-lang]');
  const hrefElements = document.querySelectorAll('[data-href-lang]');

  for (let element of Array.from(elements)) {
    const langKey = element.getAttribute('data-lang');

    if (langKey) {
      const newText = translations[lang][langKey];

      if (newText) {
        const htmlText = newText.replace(/\\n/g, '<br/>');
        element.innerHTML = htmlText;
      }

      const classKey = langKey + '_class';
      const newClass = translations[lang][classKey];

      if (newClass) {
        element.className = newClass;
      }
    }
  }

  for (let element of Array.from(hrefElements)) {
    const hrefLangKey = element.getAttribute('data-href-lang');

    if (hrefLangKey) {
      const newHref = translations[lang][hrefLangKey];

      if (newHref) {
        element.setAttribute('href', newHref);
      }
    }
  }

  updateUrl(lang);
}




// Update the URL to include the language code as a query parameter
function updateUrl(lang: string): void {
  const url = new URL(window.location.href);
  url.searchParams.set('lang', lang);
  history.replaceState(null, '', url.toString());
}
// Language switcher function
window.switchLanguage = function switchLanguage(lang: string): void {
  changeLanguage(lang);
};

window.changeLanguage = changeLanguage


// Initialize the page with the appropriate language
changeLanguage(getLanguageFromUrl());
